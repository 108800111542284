.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #303030;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

div.table{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

table, th, td {
  border: 1px solid white;
  border-collapse: collapse;
}

div.tooltip {
    position: absolute;
    text-align: center;
    /* width: 60px;
    height: 28px; */
    padding: 5px;
    font: 18px sans-serif;
    background: grey;
    border: 1px solid white;
    border-radius: 8px;
    pointer-events: none;
}
